<template>
  <a-table
    :data-source="list"
    :columns="columns"
    :scroll="{
      y: '55vh',
      x: true,
    }"
    :loading="isLoadingRecords"
    :pagination="pagination"
    class="w-100"
    @change="handleTableChange"
  >
    <template #title>
      <a-typography-title :level="5">
        Annotation Requests: &nbsp;
        <a-tag color="blue">{{ totalResult }}</a-tag>
      </a-typography-title>
    </template>

    <template #headerCell="{ title }">
      <span class="d-flex justify-content-center">{{ title }}</span>
    </template>

    <template #bodyCell="{ record, column, index }">
      <annotation-request-record
        :item="record"
        :key="index"
        :column="column"
        :labelers="labelers"
        :isLoadingLabelers="isLoadingLabelers"
        :taskLabelers="taskLabelers || []"
        :assignedLabelers="taskLabelers[record.id] || []"
        :isProgressLoading="isProgressLoading"
        :progress="progress[record.id]"
        @autoAnnotate="autoAnnotate"
        @startTraining="startTraining"
        @removeAnnotation="removeAnnotation"
        @assignLabelersToTask="assignAndRemoveLabelersToTask"
        @updateList="updateList"
      >
      </annotation-request-record>
    </template>
  </a-table>
</template>
<script>
import httpClient from '../../../../service/httpClient';
import dateHelper from '../../../shared/Helpers/dateHelper';
import AnnotationRequestRecord from './AnnotaionRequestsTableRecord.vue';
import { useToast } from 'vue-toastification';
import trainingStatuses from 'src/config/training-status-config.js';
import TaskService from '../../../../services/tasks';

export default {
  components: { AnnotationRequestRecord },
  setup() {
    const toast = useToast();
    const columns = [
      {
        title: 'Operation Id',
        dataIndex: 'id',
        key: 'task_id',
        width: '30px',
        align: 'center',
      },
      {
        title: 'Operation Name',
        dataIndex: 'taskName',
        key: 'taskName',
        width: '120px',
        align: 'center',
      },
      {
        title: 'Organization',
        dataIndex: 'Organization',
        key: 'organization',
        width: '120px',
        align: 'center',
      },
      {
        title: 'Start Time',
        dataIndex: 'startTrainingTime',
        key: 'organization',
        width: '120px',
        align: 'center',
      },
      {
        title: 'Progress (%)',
        dataIndex: 'progress',
        key: 'progress',
        width: '90px',
        align: 'center',
      },
      {
        title: 'Model Choice',
        dataIndex: 'model_choice',
        key: 'model_choice',
        width: '150px',
        align: 'center',
      },
      {
        title: 'Assigned Labelers',
        dataIndex: 'assigned_labelers',
        key: 'assigned_labelers',
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'action',
        align: 'left',
      },
    ];
    return { toast, columns };
  },
  data() {
    return {
      tableHeaders: [
        'Operation Name',
        'organization',
        'Start Time',
        'Progress (%)',
        'Assigned Labelers',
        '',
        '',
        '',
        '',
        '',
        '',
      ],
      tableRows: ['taskName', 'Organization', 'startTrainingTime'],
      list: [],
      labelers: [],
      taskLabelers: {},
      isLoadingRecords: false,
      isLoadingLabelers: true,
      progress: {},
      isProgressLoading: true,
      currentPage: 1,
      totalResult: 0,
    };
  },

  created() {
    this.getAnnotationsList();
    this.getLabelersList();
  },

  computed: {
    pagination() {
      return {
        total: this.totalResult,
        current: this.currentPage,
        showSizeChanger: false,
        position: ['bottomCenter'],
      };
    },
  },

  methods: {
    handleTableChange(pag) {
      const { current } = pag;
      this.currentPage = current;
      this.getAnnotationsList();
    },
    updateList(data) {
      const temp = this.list;
      const index = temp.findIndex((x) => x.id === data.id);
      if (temp[index]) {
        temp[index] = {
          ...temp[index],
          ...data.updatedData,
        };
      }
      this.list = temp;
    },
    async removeAnnotation(trainingRecord) {
      const { id } = trainingRecord;
      const payload = {
        train_status: trainingStatuses.new_task,
      };
      const [error, _] = await TaskService.updateTrainedTask(id, payload, true);
      if (error) {
        this.toast.error('error occured in deleting record');
        return;
      }
      const new_list = this.list.filter((x) => x.id !== id);
      this.list = new_list;
    },

    extractTaskIDs(responseObj) {
      return responseObj.map((el) => el.id);
    },

    createAnnotationInfoList(responseObj) {
      return responseObj.map((elem) => {
        return {
          ...elem,
          Organization: elem?.Organization?.Org_name || elem.Organization,
          startTrainingTime: dateHelper.formatDate(elem.startTrainingTime),
        };
      });
    },

    async getAnnotationsList() {
      this.isLoadingRecords = true;
      const res = await httpClient.getData(
        `organization/trained_tasks?train_status=${trainingStatuses.in_annotation}&page=${this.currentPage}`
      );
      this.isLoadingRecords = false;
      if (res === 'error') {
        this.toast.error('Error occured while fetching requested task!');
        return;
      }
      const task_ids = this.extractTaskIDs(res.data);
      this.list = this.createAnnotationInfoList(res.data);
      this.totalResult = res.count;
      if (this.list.length === 0) return;
      this.getProgressForLabelling(task_ids);
      this.getAssignedLabelers(task_ids);
    },

    async getProgressForLabelling(task_ids) {
      const urlForTaskProgress = `organization/task/labelling_progess?tasks=${task_ids.join()}`;
      let res = await httpClient.get(urlForTaskProgress, false, false, false);
      let temp = {};
      res.forEach((el) => {
        // temp[el.Task__id] = `${el.numLabelled} / ${el.numVideos}`;
        temp[el.Task__id] = ((el.numLabelled / el.numVideos) * 100).toFixed(1);
      });
      this.progress = temp;
      this.isProgressLoading = false;
    },

    async getLabelersList() {
      const res = await httpClient.get('account/users_list?role__name=labeler');
      if (res === 'error') {
        this.toast.error('Error occured while fetching labelers!');
        return;
      }
      this.labelers = res;
    },

    async startTraining(trainingRecord) {
      const { id: taskId } = trainingRecord;
      const res = await httpClient.patch(
        'organization/trained_task/',
        taskId,
        { train_status: trainingStatuses.training },
        true,
        false,
        true,
        'Training Message Sent!',
        'Error occurred while starting training'
      );
      if (res !== 'error')
        this.list = this.list.filter((el) => el.id !== taskId);
    },

    async autoAnnotate(task) {
      const data = {
        task_id: task.id,
        taskName: task.taskName,
        organization: task.Organization,
        stepsCount: task.stepsCount,
        action: 'auto_annotate_task',
      };
      const res = await httpClient.post(
        'generic/auto_annotate_task/',
        JSON.stringify(data)
      );
    },

    async getAssignedLabelers(task_ids) {
      const url = `cloud_source_labeling/assign_labeler?task_ids=${task_ids.join()}`;
      const res = await httpClient.get(url);
      if (res === 'error') {
        console.log('error while fetching assinged labelers');
        return;
      }
      const tasksObj = {};
      res.forEach((el) => {
        if (!tasksObj[el.task]) tasksObj[el.task] = [];
        tasksObj[el.task].push(el.labeler);
      });
      this.taskLabelers = tasksObj;
      this.isLoadingLabelers = false;
    },

    async assignAndRemoveLabelersToTask({ labelers, taskId }) {
      const prevLabellers = this.taskLabelers[taskId] || [];

      const labelersToAssign = this.getLabelersToAssign(
        labelers,
        prevLabellers,
        taskId
      );

      const labelersToRemove = this.getLabelersToUnassign(
        prevLabellers,
        labelers
      );

      if (labelersToAssign.length === 0 && labelersToRemove.length === 0) {
        this.toast.info('Please Assign / Unassign labeler first!');
        return;
      }

      const resAssign = await this.assignLabeler(labelersToAssign);

      const resUnassign = await this.unAssignLabeler(taskId, labelersToRemove);

      if ([resAssign, resUnassign].includes('error')) {
        this.toast.error('Error occured while assigning labelers!');
        return;
      }
      this.toast.success('Assigned Labeler(s) successfully!');
      this.getAnnotationsList();
    },

    async assignLabeler(payload) {
      if (payload.length === 0) return;
      console.log('bypass if ', payload.length);
      return await httpClient.post(
        'cloud_source_labeling/assign_labeler',
        payload,
        true,
        true,
        false
      );
    },

    async unAssignLabeler(taskId, labelersList) {
      if (labelersList.length === 0) return;
      const url = `cloud_source_labeling/unassign_labeler`;
      return await httpClient.patch(
        url,
        '',
        {
          taskId,
          labelers: labelersList,
        },
        true,
        true,
        false
      );
    },

    getLabelersToAssign(labelers, prevLabellers, taskId) {
      return labelers
        .filter((e) => !prevLabellers.includes(e))
        .map((e) => ({
          task: taskId,
          labeler: e,
        }));
    },

    getLabelersToUnassign(prevLabellers, labelers) {
      return prevLabellers.filter((e) => !labelers.includes(e));
    },
  },
};
</script>
<style></style>
